<template>
  <div
    :class="'agroup-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
  <div>
	  <van-image
	    width="100%"
	    height="100%"
	    :src="defaultFace"
	    fit="contain"
	  />
    
  </div>
  </div>
</template>
  
<script>
import { getCookie } from "../util";

export default {	
  data() {
    return {
      agentInfo: [],
      defaultFace:"",
      districount: 0,
      retailcount: 0,
      auditcount: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
    if (this.$route.query.orderid) {
      this.orderid = this.$route.query.orderid;
    }
    this.PAGELANG = this.LANG.pages.agentgroup;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;
    
	
	this.axios.get(this.actions.memberAuthCert).then((response) => {
	 let { code, data } = response.data;
	console.log('response.data',response.data.message)
	  if (code == 200) {
	    this.defaultFace = data.url;
	  } else {
	     console.log('1123123')
	  }
	});
	
     
  },
  methods: {
    
  },
};
</script>


<style scoped>
@media (min-width: 679px) {
  .agroup-page {
    display: none;
  }

  .pc-my-page .main {
    padding: 36px 30px;
  }

  .pc-my-page .main .credit-info .item {
    cursor: pointer;
  }
}
</style>